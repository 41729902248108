/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {


  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $('.js-toggle-menu').on('click', function(e) {
          $('.js-toggle-menu-target').slideToggle(200);
          e.preventDefault();
        });

        // http://jsfiddle.net/wuSF7/462/
        $('img.svg').each(function(){
        var $img = $(this);
        var imgID = $img.attr('id');
        var imgClass = $img.attr('class');
        var imgURL = $img.attr('src');

        $.get(imgURL, function(data) {
            // Get the SVG tag, ignore the rest
            var $svg = $(data).find('svg');

            // Add replaced image's ID to the new SVG
            if(typeof imgID !== 'undefined') {
                $svg = $svg.attr('id', imgID);
            }
            // Add replaced image's classes to the new SVG
            if(typeof imgClass !== 'undefined') {
                $svg = $svg.attr('class', imgClass+' replaced-svg');
            }

            // Remove any invalid XML tags as per http://validator.w3.org
            $svg = $svg.removeAttr('xmlns:a');

            // Check if the viewport is set, else we gonna set it if we can.
            if(!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'));
            }

            // Replace image with new SVG
            $img.replaceWith($svg);

        }, 'xml');

    });

        // Temp
        //$('.gallery-detail').on('click',function (e) {
        //  e.preventDefault();
        //  var detailId = $(this).attr("data-id");
        //  var gallerydetail = $(".gallerydetail__pic").html(galleryItems[detailId].detail);
        //  //var gallerydetail = $(".gallerydetail__pic").fadeOut(500, function() {
        //  //    $(".gallerydetail__pic").html(galleryItems[detailId].detail).fadeIn(1000)
        //  //});
        //  var gallerymodal = $('[data-remodal-id=modal]').remodal();
        //  gallerymodal.open();
        //});
        //  //var detailItems = JSON.parse(galleryItems);
        //  function nextDetail(currentId) {
        //      var i = galleryItems.indexOf(currentid);
        //      console.log('next item ='+  galleryItems[i+1]);
        //  }
        //  //function prevDetail(currentId) {
        //  //
        //  //}


          // JavaScript to be fired on the gallery (sometimes on product pages)
          var detailId;
          var galleryItemsIndex = window.galleryItemsIndex;
          var galleryItems = window.galleryItems;

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired


          // JavaScript to be fired on the gallery page, after the init JS
          $('.gallery-detail').on('click',function (e) {
              e.preventDefault();
              detailId = $(this).attr("data-id");
              galleryActions.updateDetail(detailId);
              var gallerymodal = $('[data-remodal-id=modal]').remodal();
              gallerymodal.open();
          });
          $('.nextDetail').on('click',function (e) {
              e.preventDefault();
              galleryActions.nextDetail(detailId);
          });
          $('.prevDetail').on('click',function (e) {
              e.preventDefault();
              galleryActions.prevDetail(detailId);
          });
          var galleryActions = {
              prevDetail: function(currentid) {
                  var currentidStr = currentid.toString();
                  var i = galleryItemsIndex.indexOf(currentidStr);
                  var prevIndex = (i-1 <= 0) ? 0 : i-1;
                  prevId = galleryItemsIndex[prevIndex];
                  this.updateDetail(prevId);
              },
              nextDetail: function(currentid) {
                  var currentidStr = currentid.toString();
                  var i = galleryItemsIndex.indexOf(currentidStr);
                  var nextIndex = ((i+1) >= galleryItemsIndex.length) ? i : (i+1);
                  nextId = galleryItemsIndex[nextIndex];
                  this.updateDetail(nextId);
              },
              updateDetail: function(newid) {
                  detailId = newid;
                  $(".gallerydetail__pic").fadeOut(100, function () {
                      $(".gallerydetail__pic").html(galleryItems[newid].detail).fadeIn(200);
                  });
                  //console.log('update detail to '+ detailId);
              }
          };
          window.galleryActions = galleryActions;

      }
    },
    // Home page
    'home': {
          init: function() {
              // JavaScript to be fired on the home page

              //$('img.loadcheck').on('load',function(){
              //  $('img.lowrez').delay(1000).fadeTo(1500, 0);
              //});


          },
          finalize: function() {
              // JavaScript to be fired on the home page, after the init JS
          }
      },
      'gallery': {
          init: function() {

              // JavaScript to be fired on the gallery
              //var detailId;
              //var galleryItemsIndex = window.galleryItemsIndex;
              //var galleryItems = window.galleryItems;

          },
          finalize: function() {

              //console.log('gallery page scripts');

              // JavaScript to be fired on the gallery page, after the init JS
              //$('.gallery-detail').on('click',function (e) {
              //    e.preventDefault();
              //    detailId = $(this).attr("data-id");
              //    galleryActions.updateDetail(detailId);
              //    var gallerymodal = $('[data-remodal-id=modal]').remodal();
              //    gallerymodal.open();
              //});
              //$('.nextDetail').on('click',function (e) {
              //    e.preventDefault();
              //    galleryActions.nextDetail(detailId);
              //});
              //$('.prevDetail').on('click',function (e) {
              //    e.preventDefault();
              //    galleryActions.prevDetail(detailId);
              //});
              //var galleryActions = {
              //    prevDetail: function(currentid) {
              //        var currentidStr = currentid.toString();
              //        var i = galleryItemsIndex.indexOf(currentidStr);
              //        var prevIndex = (i-1 <= 0) ? 0 : i-1;
              //        prevId = galleryItemsIndex[prevIndex];
              //        this.updateDetail(prevId);
              //    },
              //    nextDetail: function(currentid) {
              //        var currentidStr = currentid.toString();
              //        var i = galleryItemsIndex.indexOf(currentidStr);
              //        var nextIndex = ((i+1) >= galleryItemsIndex.length) ? i : (i+1);
              //        nextId = galleryItemsIndex[nextIndex];
              //        this.updateDetail(nextId);
              //    },
              //    updateDetail: function(newid) {
              //        detailId = newid;
              //        $(".gallerydetail__pic").fadeOut(100, function () {
              //            $(".gallerydetail__pic").html(galleryItems[newid].detail).fadeIn(200);
              //        });
              //        //console.log('update detail to '+ detailId);
              //    }
              //};
              //window.galleryActions = galleryActions;

          }
      },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
